import * as styles from './grid.module.scss'

import React from "react"
import Container from '../container'
import PostTile from '../post/tile'

const PostsGrid = ({ posts = [], locale }) => {
  if (!posts || posts.length === 0) return <div className={styles.empty}>Keine Beiträge gefunden</div>

  const items = posts.map(post => {
    if (post === null) return null
    return (
      <div key={post.id} className={styles.item}>
        <PostTile post={post} locale={locale} />
      </div>
    )
  })

  return (
    <section>
      <Container>
        <div className={styles.items}>
          {items}
        </div>
      </Container>
    </section>
  )
}

export default PostsGrid
