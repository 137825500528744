import Layout from '../../components/layout';
import React from 'react';
import SearchField from '../../components/search';
import Seo from '../../components/seo';

function EnglishSearch({ locale='de' }) {
  const localizedUrls = {
    de: '/de/suche',
    en: '/en/search'
  }

  return (
    <>
      <Seo title='Search'/>
      <Layout locale='en' localizedUrls={localizedUrls}>
        <SearchField locale="en"/>
      </Layout>
    </>
  )
}

export default EnglishSearch;
