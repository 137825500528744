import * as styles from './index.module.scss'

import { useLocation } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from 'react'
import icon from '../../assets/images/arrow-down.svg'
import backgroundImage from '../../assets/images/clouds.jpg'
import { translate } from '../../translations'
import Container from '../container'
import Input from '../form/input'
import PostsGrid from '../posts/grid'
import Filter from './filter'

let inputTimeout

const SearchField = React.memo(({ locale = "de" }) => {
  if (typeof window === `undefined`) return null
  return <Component locale={locale} />
})

// TODO: sort after date, new to old
const Component = React.memo(({ locale = "de" }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query DefaultSearchEntries {
      allCmsRecipe(limit: 30, sort: {order: DESC, fields: publishDate}) {
        edges {
          node {
            ...RecipeTile
          }
        }
      }
    }
  `)

  const [query, setQuery] = useState(typeof window !== `undefined` && (new URLSearchParams(window.location.search)).get('query') !== null ? (new URLSearchParams(window.location.search)).get('query') : ``)

  let urlObj = typeof window !== `undefined` ? new URL(window.location) : null

  // Get the search parameters
  const searchParams = new URLSearchParams(urlObj?.search)

  // Extract the categories as an array
  const allergens = searchParams.getAll('allergens')
  const difficulty = searchParams.getAll('difficulty')
  const categories = searchParams.getAll('categories')
  const duration = searchParams.getAll('duration').map(d => parseInt(d))

  const [displayedQuery, setDisplayedQuery] = useState(query)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(query === '' ? (data.allCmsRecipe.edges.map(edge => edge.node) || []) : [])
  const [showMenu, setShowMenu] = useState(false)

  const [filters, setFilters] = useState({
    allergens: allergens,
    difficulty: difficulty,
    categories: categories,
    duration: duration
  })

  const onChange = e => {
    clearTimeout(inputTimeout)
    const value = e.target.value
    inputTimeout = setTimeout(function () {
      setQuery(value)
      navigate(`${pathname}?query=${value}`, { replace: true })
    }, 750)
  }

  const onFilterUpdate = (data) => {
    console.log({ data })
    setFilters(data)

    if (typeof window === `undefined`) return
    // Create a URL object
    const urlObj = new URL(window.location)
    console.log({ urlObj })

    // Remove trailing slash if present
    if (urlObj.pathname.endsWith('/')) {
      urlObj.pathname = urlObj.pathname.slice(0, -1)
    }

    // Function to add array parameters to URLSearchParams
    function addArrayParams(params, key, values) {
      values.forEach(value => {
        params.append(key, value)
      })
    }

    // Create URLSearchParams object
    const searchParams = new URLSearchParams()

    // Add filters to searchParams
    for (const key in data) {
      if (data[key].length > 0) {
        addArrayParams(searchParams, key, data[key])
      }
    }

    if (query && query !== "") {
      addArrayParams(searchParams, "query", [query])
    }

    // Add the search parameters to the URL object
    urlObj.search = searchParams.toString()

    // Get the full URL as a string
    const fullUrl = urlObj.toString()
    window.history.replaceState({}, '', fullUrl)
  }

  useEffect(() => {
    async function fetchResults() {
      if (query !== "" && query.length > 0 && query.length < 1) return
      setLoading(true)
      setResults([])
      const graphqlQuery = `
        query SearchQuery(
          $query: String!,
          $locale: String!,
          $allergens: [String!],
          $categories: [String!],
          $difficulty: [String!],
          $preperationTime: Int
        ) {
          search(
            query: $query,
            locale: $locale
            allergens: $allergens
            categories: $categories
            difficulty: $difficulty
            preperationTime: $preperationTime
          ) {
            edges {
              cursor
              node {
                id
                titleDe
                titleEn
                slugDe
                slugEn
                imageDe
                imageEn
                advertisement
                type
                series {
                  __typename
                  id
                  slugDe
                  slugEn
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
          } 
        }
      `

      const _filters = {
        ...filters,
        preperationTime: filters.duration.length > 0 ? filters.duration[0] : null
      }
      const response = await fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: graphqlQuery,
          variables: {
            query,
            ..._filters,
            locale
          },
        }),
      })
      setLoading(false)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const { data } = await response.json()
      const _results = data?.search?.edges?.map(edge => edge.node) || []
      setResults(
        _results.map(result => {
          return {
            ...result,
            __typename: result.type,
            image: {
              fileDe: {
                gatsbyImageData: JSON.parse(result.imageDe)
              },
              fileEn: {
                gatsbyImageData: JSON.parse(result.imageEn)
              }
            }
          }
        })
      )
      setDisplayedQuery(query)
    }
    fetchResults()

  }, [filters, query])

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const toggleLabel = !showMenu ? translate('search.filter.labelShow', locale) : translate('search.filter.labelHide', locale)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.background} style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className={styles.input}>
            <Input onChange={onChange} placeholder={translate('search.section.placeholder', locale)} defaultValue={query} />
          </div>
          <div className={styles.toggle}>
            <button onClick={toggleMenu}>
              {toggleLabel}
              <div className={styles.icon} data-show={showMenu}>
                <img src={icon} alt='' />
              </div>
            </button>
          </div>
          <div className={styles.filter} data-show={showMenu}>
            <Filter locale={locale} filters={filters} setFilters={onFilterUpdate} />
          </div>
        </div>
        {loading ? (
          <div className={styles.status}>
            <Container>
              <>{translate('search.results.loading', locale)}</>
            </Container>
          </div>
        ) : (
          <>
            {(query !== '' &&
              <div className={styles.status}>
                <Container>
                  <>{results && results.length} {translate('search.results.matches', locale)} "{displayedQuery}"</>
                </Container>
              </div>
            )}
            {(!loading && results && results.length > 0 && <PostsGrid posts={results} locale={locale} />)}
            {(!loading && results && results.length === 0 && query === '' &&
              <div className={styles.empty}>{translate('search.results.empty', locale)}</div>
            )}
          </>
        )}
      </div>
    </>
  )
})

export default SearchField