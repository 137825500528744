import * as styles from './tile.module.scss'

import { graphql, Link } from 'gatsby'

// todo
import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import AdBadge from './ad'

const PostTile = ({ post, locale }) => {
  if (post === null) return null
  const title = getField(post, 'title', locale)
  const coverImage = getField(post.image, 'file', locale)

  return (
    <Link to={pathTo({ ...post, locale })} key={post.id} className={styles.item} data-gateway={post.gateway}>
      {(coverImage?.gatsbyImageData &&
        <div className={styles.visual}>
          {(post.advertisement &&
            <div className={styles.ad}>
              <AdBadge locale={post.locale} />
            </div>
          )}
          <GatsbyImage
            image={coverImage?.gatsbyImageData}
            alt={title}
          />
        </div>
      )}
      {(title &&
        <div className={styles.title}>{title}</div>
      )}
    </Link>
  )
}

export default PostTile

export const query = graphql`
  fragment RecipeTile on CMSRecipe {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    advertisement
    image: coverImage {
      ...TileImage
    }
  }

  fragment PostTile on CMSPost {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    advertisement
    image: coverImage {
      ...TileImage
    }
    series {
      __typename
      slugDe
      slugEn
    }
  }

  fragment TileImage on CMSAsset {
    titleDe
    titleEn
    fileDe {
      gatsbyImageData(
        height: 230
        quality: 65
        placeholder: DOMINANT_COLOR
        width: 350
      )
    }
    fileEn {
      gatsbyImageData(
        height: 230
        quality: 65
        placeholder: DOMINANT_COLOR
        width: 350
      )
    }
  }
`
